import * as React from "react";

interface BackbaudFormV2ComponentProps {
  formEnvId?: string;
  formHeaderHeight?: number;
  formId: string;
  formZone?: string;
}

const BLACKBAUD_V2_SCRIPT_URL =
  "https://sky.blackbaudcdn.net/static/reg-form-loader/5/main.js";

const DEFAULT_OPTIONS = {
  formEnvId: "p-i1G2YFRipUKIB3xiZC_LaA",
  formHeaderHeight: 0,
  formZone: "usa",
};

function isScriptAlreadyAdded(src) {
  const scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === src) {
      return true;
    }
  }
  return false;
}

// ! This component is very hacky. Perhaps someday, we can find a better way to handle this.
export default function BackbaudFormV2Component({
  formEnvId,
  formHeaderHeight,
  formId,
  formZone,
}: BackbaudFormV2ComponentProps) {
  const [data, setData] = React.useState(null);

  const ref = React.useCallback((node) => {
    if (node) {
      setTimeout(() => {
        console.debug("Checking for Blackbaud V2 form");
        if (node.children.length === 0) {
          const w = window as any;
          if (w && w.BBEventRegistrationFormLoader) {
            w.BBEventRegistrationFormLoader.newEventRegistrationForm(w);
          }
        }
      }, 125);
    }
  }, []);

  React.useEffect(() => {
    const fetchData = () => {
      const d = {
        formEnvId: formEnvId || DEFAULT_OPTIONS.formEnvId,
        formHeaderHeight: formHeaderHeight || DEFAULT_OPTIONS.formHeaderHeight,
        formId,
        formZone: formZone || DEFAULT_OPTIONS.formZone,
      };

      if (!isScriptAlreadyAdded(BLACKBAUD_V2_SCRIPT_URL)) {
        console.debug("Adding Blackbaud V2 script");
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.src = BLACKBAUD_V2_SCRIPT_URL;
        script.type = "text/javascript";
        script.onload = () => {
          console.debug("Blackbaud V2 script loaded");
          console.debug("Setting data", d);
          setData(d);
        };
        document.body.appendChild(script);
      } else {
        console.debug("Setting data", d);
        setData(d);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div
      ref={ref}
      data-blackbaud-registration-form
      data-blackbaud-registration-form-envid={data.formEnvId}
      data-blackbaud-registration-form-id={data.formId}
      data-blackbaud-registration-form-zone={data.formZone}
      data-blackbaud-registration-form-header-height={data.formHeaderHeight}
    ></div>
  );
}
